@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://unpkg.com/css.gg@2.0.0/icons/css/spinner-two-alt.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  list-style: none;
  /* font-family: "Lato", sans-serif; */
  font-family: "Rubik", sans-serif;
}



:root {
  --Main-background-color: #f8f8f8;
  --DashBoard-BG: rgba(255, 255, 255, 0.6);
  --Deeb-blu: #159bd7;
  --Novartis-Blue: #159bd7;
  --Novartis-Blue2: #064477;
  --Dark: #000;
  --White: #fff;
  --Turqis: #50e2d0;
  --Amber: #ffc100;
  --Pinky: #f1606b;
  --linear-orange: linear-gradient(to right, #e8580f, #ee9d00b2);
}
/* width */
/* Scrollbar width */
::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* Softer background color */
  border-radius: 10px; /* Rounded edges for a more modern look */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle inner shadow */
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: var(--Deeb-blu);
} */

/* Scrollbar Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;

}
/* h1,
h3,
h4,
h5,
h6,
label,
span {
  color: var(--Deeb-blu) !important;
} */

.roleBox {
  border: 1px solid gray !important;
}

.blueHover:hover {
  background-color: var(--Novartis-Blue2) !important;
}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: none !important;
}
label {
  background-color: white;
}
/* LazyLoadingAnimatation  */
.lazy-loadingImg {
  animation: Lazy 5s linear normal;
}
.btn-DarkBlue {
  background-color: var(--Novartis-Blue) !important;
  color: var(--Deeb-blu);
}
.btn-grey {
  background-color: grey !important;
  color: var(--Deeb-blu);
}
.darkBlue {
  color: var(--Deeb-blu) !important ;
}
.importCirlce {
  width: 35px !important;
  height: 35px !important;
}
@keyframes Lazy {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  90% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(0);
  }
}
fieldset {
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.MuiFormHelperText-root {
  display: none !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 5px !important;
  
  
}
.MuiIconButton-root {
  z-index: 1 !important;
  color: black !important;
}
.backButton:hover {
  background-color: #4b5563 !important ;
  color: white !important;
}
/* breadCrumbList  */
.MuiBreadcrumbs-ol {
  align-items: center !important;
}
@keyframes spinner-two-alt {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* #Spinning Login Btn ----------------- */
.gg-spinner-two-alt,
.gg-spinner-two-alt::before {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
}

.gg-spinner-two-alt {
  transform: scale(var(--ggs, 1));
  position: relative;
}

.gg-spinner-two-alt::before {
  content: "";
  position: absolute;
  border-radius: 100px;
  animation: spinner-two-alt 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-bottom-color: currentColor;
  border-top-color: currentColor;
}
/* end of spinning  */
/* .TabelCoulmTeams {
  width: 25% !important;
  height: 100% !important;
} */

/* Preiview Data  */
.showColor {
  width: 50px;
  height: 8px;
  border-radius: 5px;
}
.wrappingItems {
  background-color: var(--Novartis-Blue);
  color: white;
  border-radius: 5px;
}
/* .previewCardParent {
  width: 40vw;
  border: 1px solid;
} */
.previewCardParent h3 {
  border-bottom: 3px solid var(--Deeb-blu);
}
@media (max-width: 768px) {
  .previewCardParent {
    width: 100vw;
  
  }
}
/* subsciber table deleteIcon  */

.deleteIcon:hover {
  transform: scale(0.8);
}
.deleteIcon {
  transition: 0.5s !important;
 
}
.css-1d6wzja-MuiButton-startIcon {
  margin: 0 !important;
}
.subRowImg img {
  object-fit: cover;
  height: 64.8px !important;
}

/* loader  */
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
/* addNewSub  */
.btnAddNewSub {
  width: 50px !important;
}
.MuiOutlinedInput-input:-webkit-autofill {
  color: white !important;
}
.dropDownBorder {
  border-bottom: 1px solid grey !important;
}
.greyBgc {
  background-color: white !important;
  border: 1px solid;
  border-radius: 5px !important;
}
input {
  outline: none !important;
}
.react-datepicker-ignore-onclickoutside:focus {
  border: 0 !important;
}
.border-disappear {
  border: 1px solid transpernt !important ;
}

.EventShadow {
  box-shadow: 0 0 15px 2px rgb(234, 232, 232);
}
input:-webkit-autofill {
  border-radius: 5px !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-gga68z-MuiInputBase-input {
  border: none !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 1px 4px 1px 5px !important;
}
/* .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  border: 0 !important;
} */
[htmlFor="importFile"] {
  cursor: pointer !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 80% !important;
}
input:focus,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: white !important;
}
.react-time-picker__wrapper,
.react-time-picker {
  width: 100% !important;
  background-color: lightgray;
}
.react-time-picker input {
  border: none !important;
  height: 100% !important;
}
.test input {
  width: 100% !important;
}
.chrome-picker {
  position: absolute !important;
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .css-1bw0nnu-MuiStep-root + div {
    display: none !important;
  }
  .css-m5vj9m-MuiStepper-root {
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;

    justify-content: space-between;
    gap: 30px;
    align-items: center;
  }
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  max-height: 57.6px !important;
  overflow-y: scroll;
}
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  margin-bottom: 20px !important;
}
.EditPen {
  cursor: pointer;
}
.ErrorParent {
  position: relative;
}
.Error {
  position: absolute;
  top: -25px;
  right: 0;
}
.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  -webkit-border: 0 !important;
  -moz-border: 0 !important ;
  -ms-border: 0 !important;
  -o-border: 0 !important;
}
.MuiInputBase-input {
  padding: 8px 16px !important;
  border: 1px solid gray !important;
  border-radius: 5px !important;
}
 .MuiPaper-elevation8 {
  width: 20ch !important;
}


.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -10px !important;
  color: black !important;
  font-size: 14px !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
}
/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 32px !important;
} */
.BlockedACC {
  background-color: #97180a !important;
  color: white !important;
}
.OthersParent {
  position: relative;
}
.BtnOthers {
  position: absolute;
  right: 0;
  top: -50px;
}
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  margin: 0 !important;
  padding: 0 !important;
}
[placeholder="3:00 PM"] {
  width: 100% !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input  {
  padding: 7px 10px !important;
  padding-right: 32px !important;
 
 
  /* color: #0460A9 !important;
  font-weight: 500 !important;
  border: 1px solid #0460A9 !important; */

}

.react-datepicker-wrapper{
  width: 100%;
}

.MuiTablePagination-selectLabel  {
  margin-bottom: 0;
  color: black;

}
/* .MuiSelect-select {
  border: 1px solid black !important;
  color: black !important;
} */
.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
  color: black;
}
.MuiTableSortLabel-root {
  color: black !important;
  font-weight: bold;

}


.MuiTableHead-root {
  border-bottom: 1px solid  #e5e7eb;
}
/* .MuiTableRow-root  .MuiTableRow-head {
  border-bottom: black !important;
} */
