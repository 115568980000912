.snackBar {
  padding: 0.75rem;
  max-width: 560px;
  min-width: 300px;
  position: fixed;
  z-index: 5500;
  bottom: 16px;
  right: auto;
  left: auto;
  border-radius: 5px;
  text-align: center;
  /* display: flex; */
  /* justify-content: start; */
  background-color: rgba(1, 1, 1, 0.9);
  color: white;
  /* border: 1px solid ; */
  box-shadow: 
  /* 0 4px 8px rgb(0 0 0 / 0.7); */ 0 4px 8px rgb(0 0 0 / 0.1);

  font-family: "IBM Plex Sans", sans-serif;
  animation: snackbarInRight 200ms;
  transition: transform 0.2s ease-out;
}

@keyframes snackbarInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
