@media(max-width:768px) {
    .dateTableTitle span {
        font-size: 2.7vw;
    }
}
.exportExcel{
    cursor: pointer;
}
.TovParent{
    max-height: 100px;
    overflow-y: scroll;
}