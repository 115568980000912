.EventDetailTitle{
    position: relative;
}
.EventDetailTitle::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--Novartis-Blue);
    bottom: -4px;
    left: 0;
    border-radius: 10px;
}

@media(max-width:960px){
    .choosenCard{
        width:100%;
    }
    .EventDetailsContent{
        flex-direction: column;
    }
    
}
@media(max-width:768px){
    .EventDetailsContent{
        height: auto !important;
    }
    .choosenCard{
        width:80%;
    }
}
.EventDetailsContent{
    height: 200px ;
}