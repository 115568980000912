 .StepThreeBg {
width: 100%;
     position: relative;
     border-radius: 40px;
     border: none;
}
.StepThreeContent   {
    position: absolute;
    left: 52%;
    transform: translate(-50%,-50%);
    top: 52%;
}


/* color picker  */
.makeStyles-root-230{
    align-items: center;
}
.css-gga68z-MuiInputBase-input{
    padding: 4px !important;
}
.makeStyles-colorpickerButton-7{
    margin: 0 4px !important;
}
.colorPickerParent{
    position: relative;
    flex-grow: 1;
} 
.ColorPickerTitle{
    position: absolute;
    font-size: 12px;
    top: -10px;
    color: grey !important;
    left: 0;
    z-index: 1;
    background-color: white;
}
[alt="novartisLogo"]{
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
}
[  alt="mobileLogo"]{
    width: 500px !important;
    height: 250px !important;
}
@media(max-width:529px){
    [  alt="mobileLogo"]{
        width: 90% !important;
        height: 270px !important;

    }
}

[ alt="mobileLogo"] {
border-radius:35px !important ;
}