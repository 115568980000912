.MainContent{
    width: calc(100% - 270px) ;
    position: absolute !important;
    right:0 ;
    background-color: var(--Main-background-color);
    min-height: 100vh;
}
[alt="ProfileImg"]{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AvatarImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.AddEventBtn i{
    font-size: 18px;
}
.AddEventBtn span{
    font-size: 14px;
}
.MainContent h2 {
    font-weight: 600;
    
}
.WeekEventsCardParent{
    width: 50%;
}
@media(max-width:768px){
    .WeekEventsCardParent{
        width: 100%;
    }
    .MainContent{
        width: 100% ;
        left: 0;
        right: 0;
    }
}